import React, { useState } from "react"
import { graphql } from "gatsby"
import Helmet from "react-helmet"
import PageTransition from "gatsby-plugin-page-transitions"
import { Layout, Col } from "antd"
import withAuth from "../components/withAuthHoc"

import CustomHeader from "../components/header"
import CustomMenu from "../components/menu"
import CustomFooter from "../components/commonFooter"

import { generateDeploymentHostname } from "../services/network"

import "antd/lib/style"
import "../assets/styles"

import { useIntl } from "gatsby-plugin-intl"
import CustomBreadcrumb from "../components/commonBreadcrumb"

function isObject(obj) {
  return !!(obj && typeof obj === "object" && obj.constructor === Object)
}

const Template = ({ data }) => {
  const intl = useIntl()
  const [showMenu, setShowMenu] = useState(false)
  const pagePath = {
    JUPYTER: { path: "menu-analytics", page: "menu-jupyter" },
    RAPIDMINER: { path: "menu-analytics", page: "menu-rapidminer" },
    "EXCEL ADDIN": { path: "menu-analytics", page: "menu-exceladdin" },
    "BUSINESS INTELLIGENCE TOOLS": {
      path: "menu-analytics",
      page: "menu-bi-tools",
    },
    "MQTT ACCUMULATOR": { path: "menu-cph-tool", page: "mqtt-accumulator" },
    GATEWAY: { path: "menu-cph-tool", page: "gateway" },
    DOC: { path: "menu-help", page: "menu-doc" },
    HELP: { path: "menu-help", page: "menu-help" },
    FAQ: { path: "menu-help", page: "menu-faq" },
    "COOKIE POLICY": { path: "menu-help", page: "cookie-policy" },
    "PRIVACY POLICY": { path: "menu-help", page: "privacy-policy" },
    DATE: { path: "menu-help", page: "menu-dates" },
    "ADVANCED TRANSFORMATIONS": {
      path: "menu-help",
      page: "menu-advanced-transformations",
    },
    "-": { path: "", page: "-" },
  }

  const getCurrentPage = frontmatter => {
    // Throws error on build, don't know why
    try {
      if (!!frontmatter && !!frontmatter["path"]) {
        const sliceValue = frontmatter.path.slice(
          frontmatter.path.lastIndexOf("/") + 1
        )
        if (sliceValue && sliceValue.replaceAll) {
          return sliceValue.replaceAll("-", " ").toUpperCase()
        }
      }
      return "-"
    } catch (err) {
      return "-"
    }
  }

  const { markdownRemark = {} } = data // data.markdownRemark holds our post data
  if (!markdownRemark || !markdownRemark.frontmatter) {
    return null
  }
  let parsedUrls
  let currentPage = getCurrentPage(markdownRemark.frontmatter)
  const crumbs = [
    pagePath[currentPage]
      ? intl.formatMessage({
          id: pagePath[currentPage]["page"],
        })
      : markdownRemark.frontmatter.title,
  ]
  try {
    parsedUrls = JSON.parse(markdownRemark.frontmatter.urls)
    if (isObject(parsedUrls) && markdownRemark.html) {
      Object.entries(parsedUrls).forEach(([k, v]) => {
        markdownRemark.html = markdownRemark.html.replace(
          new RegExp(`(%25${k}%25|%${k}%)`, "g"),
          generateDeploymentHostname(v)
        )
      })
    }
    return (
      <div className={showMenu ? "site block-scroll" : "site"}>
        <Helmet>
          <html className={null} />
          <title itemProp="name" lang="es">
            Knolar | {currentPage}
          </title>
        </Helmet>
        <Layout>
          <CustomHeader toggleShowMenu={() => setShowMenu(!showMenu)} />
          <Layout
          // style={{ padding: "104px 40px 0 40px", background: "white" }}
          >
            <CustomMenu
              selected={[markdownRemark.frontmatter.key]}
              visible={showMenu}
              collapsed={true}
            />
            <div className="md-page">
              {showMenu ? (
                <div
                  className="menu-blocker"
                  onClick={() => setShowMenu(false)}
                />
              ) : (
                ""
              )}
              <CustomBreadcrumb crumbs={crumbs} />
              <Col
                span={20}
                offset={2}
                style={{ width: "100%", marginLeft: 0 }}
              >
                <PageTransition>
                  <div className="blog-post-container">
                    <div className="blog-post">
                      <div
                        style={{ minHeight: "calc(100vh - 185px)" }}
                        dangerouslySetInnerHTML={{
                          __html: markdownRemark.html,
                        }}
                      />
                    </div>
                  </div>
                </PageTransition>
              </Col>
              <CustomFooter isMarkdown={true} />
            </div>
          </Layout>
        </Layout>
      </div>
    )
  } catch (ex) {
    return null
  }
}

export default withAuth(Template)

/**
 * (isidro.iglesias)
 * '$language' is in te component's environment since the use of the i18n plugin
 * (left so since it simplifies Markdowns translations a lot with this plugin ("gatsby-plugin-intl"))
 */
export const pageQuery = graphql`
  query($localizedPath: String!, $language: String!) {
    markdownRemark(
      frontmatter: { lang: { eq: $language }, path: { eq: $localizedPath } }
    ) {
      html
      frontmatter {
        path
        key
        lang
        urls
      }
    }
  }
`
